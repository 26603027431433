import t from "../../actions/FilterActions/types";

const initialState = {
    filterData: {},
    filterDataResponse: {
        content: []
    },
    pendingDataResponse: {
        content: []
    },
    purchaseDataResponse: {
        content: []
    },
    traditionalDataResponse: [],
    documentLibrary: {
        content: []
    },
    documentLibraryTotalPages: 0,
    walletDataResponse: {
        content: []
    },
    totalPages: 0,
    paymentDataResponse: {
        content: []
    },
    paymentTotalPages: 0,
    exportData: [],
    searchData: {
        "invoices": {},
        "payments": {
            "merchantBuyerPlatformsId": {},
            "paymentType": "Settlement",
            "sort":"paymentsDate DESC"
        },
        "PurchaseOrder": {},
        "pending": {
            "merchantBuyerPlatformsId": {},
            "sort":"creationTimestamp DESC"
        },
        "wallet": {
            "merchantBuyerPlatformsId": {},
            "sort":"transactionDate DESC,id DESC"
        },
        "traditionalrequests": {
            "merchantBuyerPlatformsId": {},
            "sort":"creationTimestamp DESC"
        },
        "factparam": {
            "merchantBuyerPlatformsId": {}
        },
        "riskDashboard": {
            "merchantBuyerPlatformsId": {}
        },
        "remittance": {
            "merchantBuyerPlatformsId": {}
        },
        "bankPayment": {
            "merchantBuyerPlatformsId": {},
            "sort":"id desc"
        },
        "bankReciept": {
            "merchantBuyerPlatformsId": {},
            "sort":"date desc,settlementStatus asc,id desc"
        },
        "amazon": {
            "merchantBuyerPlatformsId": {},
            "sort":"invoiceAdded asc,invoiceCreationDate desc"
        },
        "advances": {
            "merchantBuyerPlatformsId": {}
        },
    },
    bankPaymentDataResponse:[],
    bankPaymentDataTotalPage:0,
    bankRecieptDataResponse:[],
    bankRecieptDataTotalPage:0,

    amazonDataResponse:[],
    amazonDataTotalPage:0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case t.SET_FILTER_DATA:
            return {
                ...state,
                filterData: action.payload
            }
            break;
        case t.RESET_FILTER_DATA:
            return {
                ...state,
                filterData: {}
            }
            break;
        case t.GET_FILTER_DATA:
            return {
                ...state,
                filterDataResponse: action.payload ? action.payload : state.filterDataResponse,
                totalPages: action.payload.totalPages ? action.payload.totalPages : 0,

                pendingDataResponse: action.payload.pending ? action.payload.pending.content : state.pendingDataResponse,
                pendingTotalPages: action.payload.pending ? action.payload.pending.totalPages : 0,

                purchaseDataResponse: action.payload.purchaseOrder ? action.payload.purchaseOrder.content : state.purchaseDataResponse,
                purchaseTotalPages: action.payload.purchaseOrder ? action.payload.purchaseOrder.totalPages : 0,

                traditionalDataResponse: action.payload.traditionalrequests ? action.payload.traditionalrequests.content : [],
                traditionalTotalPages: action.payload.traditionalrequests ? action.payload.traditionalrequests.totalPages : 0,
                
                traditionalDataResponse: action.payload.traditionalrequests ? action.payload.traditionalrequests.content : [],
                traditionalTotalPages: action.payload.traditionalrequests ? action.payload.traditionalrequests.totalPages : 0,
            }
            break;
        case t.GET_FILTER_DATA_PAYMENTS:
            return {
                ...state,
                paymentDataResponse: action.payload ? action.payload.payments : state.paymentDataResponse,
                paymentTotalPages: action.payload.totalPages ? action.payload.totalPages : 0,
            }
            break;
        case t.GET_FILTER_DATA_WALLET:
            return {
                ...state,
                walletDataResponse: action.payload ? action.payload : state.filterDataResponse,
                walletTotalPages: action.payload.totalPages ? action.payload.totalPages : 0,

            }
            break;
        case t.GET_EXPORT_DATA:
            return {
                ...state,
                exportData: action.payload.content
            }
            break;

        case t.GET_EXPORT_PAYMENT_DATA:
            return {
                ...state,
                exportData: action.payload.payments.content
            }
            break;
        case t.GET_EXPORT_PURCHASE_ORDER_DATA:
            return {
                ...state,
                exportData: action.payload.purchaseOrder.content
            }
            break;
        case t.GET_EXPORT_PENDING_DATA:
            return {
                ...state,
                exportData: action.payload.pending.content
            }
            break;
        case t.GET_EXPORT_WALLET_DATA:
            return {
                ...state,
                exportData: action.payload.content
            }
            break;
        case t.GET_EXPORT_REQUEST_DATA:
            return {
                ...state,
                exportData: action.payload.traditionalrequests.content
            }
            break;
        case t.GET_DOCUMENT_LIBRARY_DATA:

            return {
                ...state,
                documentLibrary: action.payload ? action.payload.content : state.documentLibrary,
                documentLibraryTotalPages: action.payload ? action.payload.totalPages : 0
            }
            break;
        case t.SET_SEARCH_DATA:
            console.log('action.requestFor',action.requestFor)
            switch (action.requestFor) {
                
                case "invoices":
                    if (action.payload.sort) {
                        state.searchData.invoices.sort = action.payload.sort;
                    }

                    if (action.payload.type && action.payload.type != "") {
                        state.searchData.invoices.type = action.payload.type;
                    } else if (action.payload.purchaseOrderNum === "") {
                        delete state.searchData.invoices.purchaseOrderNum
                    }

                    if (action.payload.invoiceStatus && action.payload.invoiceStatus != "") {
                        state.searchData.invoices.invoiceStatus = action.payload.invoiceStatus;
                    } else if (action.payload.invoiceStatus === "") {
                        delete state.searchData.invoices.invoiceStatus
                    }

                    if (action.payload.invoiceNumber && action.payload.invoiceNumber != "") {
                        state.searchData.invoices.invoiceNumber = action.payload.invoiceNumber;
                    } else if (action.payload.invoiceNumber === "") {
                        delete state.searchData.invoices.invoiceNumber
                    }

                    if (action.payload.tradRequestId && action.payload.tradRequestId != "") {
                        state.searchData.invoices.tradRequestId = action.payload.tradRequestId;
                    } else if (action.payload.tradRequestId === "") {
                        delete state.searchData.invoices.tradRequestId
                    }
                    if (action.payload.fromDate && action.payload.fromDate != "") {
                        state.searchData.invoices.fromDate = action.payload.fromDate;
                    } else if (action.payload.fromDate === "") {
                        delete state.searchData.invoices.fromDate
                    }
                    if (action.payload.toDate && action.payload.toDate != "") {
                        state.searchData.invoices.toDate = action.payload.toDate;
                    } else if (action.payload.toDate === "") {
                        delete state.searchData.invoices.toDate
                    }
                    break;

                case "payments":
                    if (action.payload.sort) {
                        state.searchData.payments.sort = action.payload.sort;
                    }

                    if (action.payload.paymentsNumber && action.payload.paymentsNumber != "") {
                        state.searchData.payments.paymentsNumber = action.payload.paymentsNumber;
                    } else if (action.payload.paymentsNumber === "") {
                        delete state.searchData.payments.paymentsNumber
                    }
                    if (action.payload.fromDate && action.payload.fromDate != "") {
                        state.searchData.payments.fromDate = action.payload.fromDate;
                    } else if (action.payload.fromDate === "") {
                        delete state.searchData.payments.fromDate
                    }
                    if (action.payload.toDate && action.payload.toDate != "") {
                        state.searchData.payments.toDate = action.payload.toDate;
                    } else if (action.payload.toDate === "") {
                        delete state.searchData.payments.toDate
                    }

                    break;

                case "PurchaseOrder":
                    if (action.payload.sort) {
                        state.searchData.PurchaseOrder.sort = action.payload.sort;
                    }
                    if (action.payload.purchaseOrderNum && action.payload.purchaseOrderNum != "") {
                        state.searchData.PurchaseOrder.purchaseOrderNum = action.payload.purchaseOrderNum;
                    } else if (action.payload.purchaseOrderNum === "") {
                        delete state.searchData.PurchaseOrder.purchaseOrderNum
                    }
                    if (action.payload.fromDate && action.payload.fromDate != "") {
                        state.searchData.PurchaseOrder.fromDate = action.payload.fromDate;
                    } else if (action.payload.fromDate === "") {
                        delete state.searchData.PurchaseOrder.fromDate
                    }
                    if (action.payload.toDate && action.payload.toDate != "") {
                        state.searchData.PurchaseOrder.toDate = action.payload.toDate;
                    } else if (action.payload.toDate === "") {
                        delete state.searchData.PurchaseOrder.toDate
                    }
                    break;

                case "pending":
                    if (action.payload.sort) {
                        state.searchData.pending.sort = action.payload.sort;
                    }
                    if (action.payload.id || action.payload.id === "") {
                        state.searchData.pending.id = action.payload.id;
                    }
                    if (action.payload.status && action.payload.status != "") {
                        state.searchData.pending.status = action.payload.status;
                    } else if (action.payload.status === "") {
                        delete state.searchData.pending.status;
                    }
                    if (action.payload.fromDate && action.payload.fromDate != "") {
                        state.searchData.pending.fromDate = action.payload.fromDate;
                    } else if (action.payload.fromDate === "") {
                        delete state.searchData.pending.fromDate
                    }
                    if (action.payload.toDate && action.payload.toDate != "") {
                        state.searchData.pending.toDate = action.payload.toDate;
                    } else if (action.payload.toDate === "") {
                        delete state.searchData.pending.toDate
                    }
                    break;
                case "wallet":
                    if (action.payload.sort) {
                        state.searchData.wallet.sort = action.payload.sort;
                    }
                    if (action.payload.fromDate && action.payload.fromDate != "") {
                        state.searchData.wallet.fromDate = action.payload.fromDate;
                    } else if (action.payload.fromDate === "") {
                        delete state.searchData.wallet.fromDate
                    }
                    if (action.payload.toDate && action.payload.toDate != "") {
                        state.searchData.wallet.toDate = action.payload.toDate;
                    } else if (action.payload.toDate === "") {
                        delete state.searchData.wallet.toDate
                    }
                    break;

                case "traditionalrequests":
                    if (action.payload.sort) {
                        state.searchData.traditionalrequests.sort = action.payload.sort;
                    }
                    if (action.payload.status && action.payload.status != "") {
                        state.searchData.traditionalrequests.status = action.payload.status;
                    } else if (action.payload.status === "") {
                        delete state.searchData.traditionalrequests.status;
                    }
                    if (action.payload.requestId && action.payload.requestId != "") {
                        state.searchData.traditionalrequests.requestId = action.payload.requestId;
                    } else if (action.payload.requestId === "") {
                        delete state.searchData.traditionalrequests.requestId
                    }
                    if (action.payload.fromDate && action.payload.fromDate != "") {
                        state.searchData.traditionalrequests.fromDate = action.payload.fromDate;
                    } else if (action.payload.fromDate === "") {
                        delete state.searchData.traditionalrequests.fromDate
                    }
                    if (action.payload.toDate && action.payload.toDate != "") {
                        state.searchData.traditionalrequests.toDate = action.payload.toDate;
                    } else if (action.payload.toDate === "") {
                        delete state.searchData.traditionalrequests.toDate
                    }
                    if (action.payload.dueDate && action.payload.dueDate != "") {
                        state.searchData.traditionalrequests.dueDate = action.payload.dueDate;
                    } else if (action.payload.dueDate === "") {
                        delete state.searchData.traditionalrequests.dueDate
                    }

                    break;
                case "bankPayment":
                    if (action.payload.sort) {
                        state.searchData.bankPayment.sort = action.payload.sort;
                    }
                    if (action.payload.status && action.payload.status != "") {
                        state.searchData.bankPayment.status = action.payload.status;
                    } else if (action.payload.status === "") {
                        delete state.searchData.bankPayment.status;
                    }
                    if (action.payload.merchantId && action.payload.merchantId != "") {
                        state.searchData.bankPayment.merchantId = action.payload.merchantId;
                    } else if (action.payload.merchantId === "") {
                        delete state.searchData.bankPayment.merchantId;
                    }
                    if (action.payload.requestId && action.payload.requestId != "") {
                        state.searchData.bankPayment.requestId = action.payload.requestId;
                    } else if (action.payload.requestId === "") {
                        delete state.searchData.bankPayment.requestId
                    }
                    break;
                case "bankReciept":
                    if (action.payload.sort) {
                        state.searchData.bankReciept.sort = action.payload.sort;
                    }
                    if (action.payload.merchantId && action.payload.merchantId != "") {
                        state.searchData.bankReciept.merchantId = action.payload.merchantId;
                    } else if (action.payload.merchantId === "") {
                        delete state.searchData.bankReciept.merchantId;
                    }
                    // if (action.payload.date && action.payload.date != "") {
                    //     state.searchData.bankReciept.date = action.payload.date;
                    // } else if (action.payload.date === "") {
                    //     delete state.searchData.bankReciept.date
                    // }
                    if (action.payload.settlementStatus && action.payload.settlementStatus != "") {
                        state.searchData.bankReciept.settlementStatus = action.payload.settlementStatus;
                    } else if (action.payload.settlementStatus === "") {
                        delete state.searchData.bankReciept.settlementStatus
                    }
                    if (action.payload.bankReferenceNo && action.payload.bankReferenceNo != "") {
                        state.searchData.bankReciept.bankReferenceNo = action.payload.bankReferenceNo;
                    } else if (action.payload.bankReferenceNo === "") {
                        delete state.searchData.bankReciept.bankReferenceNo
                    }
                    if (action.payload.activeBpIds && action.payload.activeBpIds != "") {
                        state.searchData.bankReciept.activeBpIds = action.payload.activeBpIds;
                    } else if (action.payload.activeBpIds === "") {
                        delete state.searchData.bankReciept.activeBpIds
                    }
                    if (action.payload.fromDate && action.payload.fromDate != "") {
                        state.searchData.bankReciept.fromDate = action.payload.fromDate;
                    } else if (action.payload.fromDate === "") {
                        delete state.searchData.bankReciept.fromDate
                    }
                    if (action.payload.toDate && action.payload.toDate != "") {
                        state.searchData.bankReciept.toDate = action.payload.toDate;
                    } else if (action.payload.toDate === "") {
                        delete state.searchData.bankReciept.toDate
                    }
                    break;

                case "amazon" : 
                    if (action.payload.sort) {
                        state.searchData.amazon.sort = action.payload.sort;
                    }

                    // if (action.payload?.merchantBuyerPlatformsId && action.payload?.merchantBuyerPlatformsId != "") {
                    //     let merchantBuyerPlatformsId = {
                    //         "merchantId":action.payload.merchantBuyerPlatformsId.merchantId,
                    //         "buyerPlatformsId":action.payload.merchantBuyerPlatformsId.buyerPlatformsId
                    //     };
                    //     state.searchData.amazon.merchantBuyerPlatformsId = merchantBuyerPlatformsId;
                    // } else if (action.payload?.merchantBuyerPlatformsId === "") {
                    //     delete state.searchData.amazon.merchantBuyerPlatformsId;
                    //     delete action.payload.merchantBuyerPlatformsId;
                    // }

                    if (action.payload.invoiceNumber && action.payload.invoiceNumber != "") {
                        state.searchData.amazon.invoiceNumber = action.payload.invoiceNumber;
                    } else if (action.payload.invoiceNumber === "") {
                        delete state.searchData.amazon.invoiceNumber
                    }
                    if (action.payload.fromDate && action.payload.fromDate != "") {
                        state.searchData.amazon.fromDate = action.payload.fromDate;
                    } else if (action.payload.fromDate === "") {
                        delete state.searchData.amazon.fromDate
                    }
                    if (action.payload.toDate && action.payload.toDate != "") {
                        state.searchData.amazon.toDate = action.payload.toDate;
                    } else if (action.payload.toDate === "") {
                        delete state.searchData.amazon.toDate
                    }
                    break;
                
                case "advances" : 
                        if (action.payload.sort) {
                            state.searchData.advances.sort = action.payload.sort;
                        }
                        console.log('action.payload',action.payload);
                        if (action.payload.status && action.payload.status != "") {
                            state.searchData.advances.status = action.payload.status;
                        } else if (action.payload.status === "") {
                            delete state.searchData.advances.status
                        }
                        if (action.payload.addedDate && action.payload.addedDate != "") {
                            state.searchData.advances.addedDate = action.payload.addedDate;
                        } else if (action.payload.addedDate === "") {
                            delete state.searchData.advances.addedDate
                        }
                        if (action.payload.postedDate && action.payload.postedDate != "") {
                            state.searchData.advances.postedDate = action.payload.postedDate;
                        } else if (action.payload.postedDate === "") {
                            delete state.searchData.advances.postedDate
                        }
                    break;
                default:
                    break;
            }

            // add merchantBuyerPlatformsId
    
            if (action.payload.merchantBuyerPlatformsId ) {
                state.searchData.wallet.merchantBuyerPlatformsId = action.payload.merchantBuyerPlatformsId;
                state.searchData.traditionalrequests.merchantBuyerPlatformsId = action.payload.merchantBuyerPlatformsId;
                state.searchData.pending.merchantBuyerPlatformsId = action.payload.merchantBuyerPlatformsId;
                state.searchData.payments.merchantBuyerPlatformsId = action.payload.merchantBuyerPlatformsId;
                state.searchData.factparam.merchantBuyerPlatformsId = action.payload.merchantBuyerPlatformsId;
                state.searchData.riskDashboard.merchantBuyerPlatformsId = action.payload.merchantBuyerPlatformsId;
                state.searchData.remittance.merchantBuyerPlatformsId = action.payload.merchantBuyerPlatformsId;
                // state.searchData.bankPayment.merchantBuyerPlatformsId = action.payload.merchantBuyerPlatformsId;
                state.searchData.amazon.merchantBuyerPlatformsId = action.payload.merchantBuyerPlatformsId;
                state.searchData.advances.merchantBuyerPlatformsId = action.payload.merchantBuyerPlatformsId;
            }

            // add merchant id 
            if (action.payload.merchantId && action.payload.merchantId != "") {
                switch (action.requestFor) {
                    case "wallet":
                        state.searchData.wallet.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; //wallet
                    break;
                    case "traditionalrequests":
                        state.searchData.traditionalrequests.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; // request
                    break;
                    case "pending":
                        state.searchData.pending.merchantBuyerPlatformsId.merchantId = action.payload.merchantId;// pending
                    break;
                    case "PurchaseOrder":
                        state.searchData.PurchaseOrder.merchantId = action.payload.merchantId; // PurchaseOrder
                    break;
                    case "invoices":
                        state.searchData.invoices.merchantId = action.payload.merchantId; // invoices
                    break;
                    case "payments":
                        state.searchData.payments.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; //payments

                    break;
                    case "factparam":
                        state.searchData.factparam.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; //factparam
                    break;
                    case "riskDashboard":
                        state.searchData.riskDashboard.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; //riskDashboard
                    break;
                    case "remittance":
                        state.searchData.remittance.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; //remittance
                    break;
                    case "bankPayment":
                        state.searchData.bankPayment.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; //bankPayment
                    break;
                    case "amazon":
                        state.searchData.amazon.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; //amazon
                    break;
                    case "advances":
                        state.searchData.advances.merchantBuyerPlatformsId.merchantId = action.payload.merchantId; //advances
                    break;
                    default:
                    break;
                }
            }

            // delete merchant id

            if (action.payload.merchantId === "") {
                delete state.searchData.wallet.merchantBuyerPlatformsId.merchantId;  // wallet 
                delete state.searchData.traditionalrequests.merchantBuyerPlatformsId.merchantId;  // request
                delete state.searchData.pending.merchantBuyerPlatformsId.merchantId; // pending
                delete state.searchData.PurchaseOrder.merchantId; // PurchaseOrder
                delete state.searchData.invoices.merchantId; // invioces
                delete state.searchData.payments.merchantBuyerPlatformsId.merchantId;  // payments 
                delete state.searchData.factparam.merchantBuyerPlatformsId.merchantId;  // factparam 
                delete state.searchData.riskDashboard.merchantBuyerPlatformsId.merchantId;  // riskDashboard 
                delete state.searchData.remittance.merchantBuyerPlatformsId.merchantId;  // remittance 
                // delete state.searchData.bankPayment.merchantBuyerPlatformsId.merchantId;  // bankPayment 
                delete state.searchData.amazon.merchantBuyerPlatformsId.merchantId;  // amazon 
                delete state.searchData.advances.merchantBuyerPlatformsId.merchantId;  // advances 

            }

            // add buyer platform

            if (action.payload.buyerPlatformsId && action.payload.buyerPlatformsId != "") {
                console.log('action.payload',action.payload);
                
                switch (action.requestFor) {
                    case "wallet":
                        state.searchData.wallet.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId; // wallet 
                    break;
                    case "traditionalrequests":
                        state.searchData.traditionalrequests.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId; //request
                    break;
                    case "pending":
                        state.searchData.pending.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId;// pending
                    break;
                    case "PurchaseOrder":
                        state.searchData.PurchaseOrder.buyerPlatformsId = action.payload.buyerPlatformsId;// PurchaseOrder
                    break;
                    case "invoices":
                        state.searchData.invoices.buyerPlatformId = action.payload.buyerPlatformsId; // invoices
                    break;
                    case "payments":
                        state.searchData.payments.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId;  // payments
                    break;
                    case "factparam":
                        state.searchData.factparam.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId;  // factparm
                    break;
                    case "riskDashboard":
                        state.searchData.riskDashboard.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId;  // riskDashboard
                    break;
                    case "remittance":
                        state.searchData.remittance.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId;  // remittance
                    break;
                    case "bankPayment":
                        // state.searchData.bankPayment.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId;  // bankPayment
                    break;
                    case "amazon":
                        state.searchData.amazon.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId; //amazon
                    break;
                    case "advances":
                        state.searchData.advances.merchantBuyerPlatformsId.buyerPlatformsId = action.payload.buyerPlatformsId;  // advances
                    break;
                    default:
                    break;
                }
            }


            // delete buyerplatform 
            if (action.payload.buyerPlatformsId === "") {
                delete state.searchData.wallet.merchantBuyerPlatformsId.buyerPlatformsId; // wallet
                delete state.searchData.traditionalrequests.merchantBuyerPlatformsId.buyerPlatformsId; //request
                delete state.searchData.pending.merchantBuyerPlatformsId.buyerPlatformsId; // pending
                delete state.searchData.PurchaseOrder.buyerPlatformsId; // pending
                delete state.searchData.invoices.buyerPlatformId; // invoices
                delete state.searchData.payments.merchantBuyerPlatformsId.buyerPlatformsId;  // payments
                delete state.searchData.factparam.merchantBuyerPlatformsId.buyerPlatformsId;  // factparam
                delete state.searchData.riskDashboard.merchantBuyerPlatformsId.buyerPlatformsId;  // riskDashboard
                delete state.searchData.remittance.merchantBuyerPlatformsId.buyerPlatformsId;  // remittance
                // delete state.searchData.bankPayment.merchantBuyerPlatformsId.buyerPlatformsId;  // bankPayment
                delete state.searchData.amazon.merchantBuyerPlatformsId.buyerPlatformsId;  // amazon
                delete state.searchData.advances.merchantBuyerPlatformsId.buyerPlatformsId;  // advances

            }

            console.log('state.searchData',state.searchData)
            return {
                ...state,
                searchData: state.searchData
            }
            break;
        case t.GET_BANK_PAYMENT_FILTER_DATA:
            return {
                ...state,
                bankPaymentDataResponse:action.payload?.content ?? [],
                bankPaymentDataTotalPage:action.payload?.totalPages ?? 0
            }
            break;
        case t.GET_BANK_RECIEPT_FILTER_DATA:
            return {
                ...state,
                bankRecieptDataResponse:action.payload?.content ?? [],
                bankRecieptDataTotalPage:action.payload?.totalPages ?? 0
            }
            break;

        case t.GET_AMAZON_FILTER_DATA:
            return {
                ...state,
                amazonDataResponse:action.payload?.content ?? [],
                amazonDataTotalPage:action.payload?.totalPages ?? 0,
            }
            break
        default:
            return state;
            break;
    }
}