import React, { useEffect, useState } from "react";
import "./Header.css";
import Notifications from "../../Model/Notifications";
// import { checkTokenExpired } from '../../../services/utilService';
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
//import {FunderLogo} from '../assests/FunderLogo.jpg';
import { Api, fetchAPI } from "../../../services/httpService";
import Swal from "sweetalert2";
import { useIdleTimer } from "react-idle-timer";
import { isAllowSessionTime } from "../../../baseURL";
import Modals from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { url } from "../../../baseURL";
import { socketUrl } from "../../../baseURL";
import socketIoClient from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

const Header = () => {
  let adminSocket;
  const history = useHistory();
  const [isIdle, setIsIdle] = useState(false);
  // checkTokenExpired();
  const logo = require("../../../assests/Funder.ai.png");
  const bell = require("../../../assests/bell.png");
  const circleWithName = require("../../../assests/Eclipse.png");
  // Utility to generate or retrieve the current tab ID
  const getOrSetTabId = () => {
    let storedTabId = sessionStorage.getItem("tabId");
    if (!storedTabId) {
      storedTabId = uuidv4();
      sessionStorage.setItem("tabId", storedTabId);
    }
    return storedTabId;
  };

  const tabId = getOrSetTabId();

  const [notificationsModal, setNotificationsModal] = useState(false);
  const closeModal = (data) => setNotificationsModal(data);

  const logout = async () => {
    Swal.fire({
      title: "Are you sure!",
      text: "Do you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "var(--bg-theme)",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await Api("post", "/users/logout");
        if (res) {
          localStorage.clear();
          window.location = "/";
        }
      }
    });
  };

  const updateIdleState = (state) => {
    try {
      const idleStates = JSON.parse(localStorage.getItem("idleState")) || {};
      idleStates[tabId] = state;
      localStorage.setItem("idleState", JSON.stringify(idleStates));
      window.postMessage({ type: "UPDATE_IDLE_STATE", idleStates }, "*");
    } catch (error) {
      console.error("Failed to update localStorage:", error);
    }
  };

  // Listen for updates from other tabs
  window.addEventListener("message", (event) => {
    if (event.data?.type === "UPDATE_IDLE_STATE") {
      localStorage.setItem("idleState", JSON.stringify(event.data.idleStates));
    }
    if (event.data?.type === "REFRESH_ALL_TABS") {
      console.log("Message received, reloading...");
      window.location.reload();
    }
  });

  // document.addEventListener("visibilitychange", () => {
  //   if (document.hidden) {
  //     console.log("Tab is now hidden. Marking as idle.");
  //     updateIdleState("idle");
  //   } else {
  //     console.log("Tab is now visible. Marking as active.");
  //     updateIdleState("active");
  //   }
  // });
  // // Periodic Sync Check
  // setInterval(() => {
  //   const idleStates =
  //     JSON.parse(localStorage.getItem("idleState") || "{}") || {};
  //   // If the tab is inactive, force it to update
  //   if (document.hidden) {
  //     updateIdleState("idle");
  //   } else {
  //     updateIdleState("active");
  //   }
  // }, 5000);

  const updateTimerHtml = (time) => {
    return `<div style="color: #009e49; background: #fff; padding: 10px; border-radius: 5px;">
                You will be logged out in <b>${time}</b> seconds.
            </div>`;
  };

  const handleOnIdle = async () => {
    console.log(
      "handleOnIdle function called",
      new Date().toLocaleTimeString()
    );
    updateIdleState("idle");
    setIsIdle(true);

    const allTabsIdle = Object.values(
      JSON.parse(localStorage.getItem("idleState") || "{}")
    ).every((state) => state === "idle");

    if (allTabsIdle) {
      localStorage.setItem(
        "triggerPopup",
        JSON.stringify({ time: Date.now() })
      );
      openIdlePopup();
    }
  };

  let idleInterval;
  async function openIdlePopup(startTime) {
    console.log("All tabs are idle. Showing idle warning.");
    // Ensure startTime is valid
    const validStartTime = Number(startTime) || Date.now();
    let timeRemaining = Math.max(
      59 - Math.floor((Date.now() - validStartTime) / 1000),
      0
    );
    const loginUserId = localStorage.getItem("_id");

    const result = await Swal.fire({
      title: "You have been inactive",
      html: updateTimerHtml(timeRemaining),
      icon: "warning",
      timer: timeRemaining * 1000,
      timerProgressBar: true,
      showCancelButton: false,
      confirmButtonText: "Stay Logged In",
      allowOutsideClick: false,
      didOpen: () => {
        if (idleInterval) clearInterval(idleInterval); // Clear any previous intervals
        idleInterval = setInterval(() => {
          timeRemaining = Math.max(
            59 - Math.floor((Date.now() - validStartTime) / 1000),
            0
          );
          const htmlContainer = Swal.getHtmlContainer();
          if (htmlContainer) {
            htmlContainer.innerHTML = updateTimerHtml(timeRemaining);
          }
          if (timeRemaining <= 0) {
            clearInterval(idleInterval);
          }
        }, 1000);
      },
      willClose: () => {
        clearInterval(idleInterval); // Clear the interval when popup closes
      },
    });

    if (result.isConfirmed) {
      try {
        const resultOtp = await fetchAPI(
          "POST",
          `/common/generateVerificationOTP?userId=${loginUserId}&event=refreshToken&sendTo=email`
        );
        if (resultOtp.success) {
          Swal.fire({
            title: "Verify OTP",
            input: "text",
            inputLabel: "Please enter the OTP sent to your email.",
            inputPlaceholder: "Enter OTP",
            inputAttributes: {
              maxlength: 6,
              autocapitalize: "off",
              autocorrect: "off",
            },
            showCancelButton: false,
            confirmButtonText: "Submit",
            allowOutsideClick: false,
            preConfirm: async (otp) => {
              if (!otp) {
                Swal.showValidationMessage("Please enter a valid OTP.");
                return false;
              }
              try {
                const currentToken = localStorage
                  .getItem("_token")
                  .replace("Bearer ", "");
                const res = await fetchAPI(
                  "POST",
                  `/common/validateVerificationOTP?userId=${loginUserId}&event=refreshToken&otp=${otp}&oldToken=${currentToken}`
                );
                if (res.success) {
                  localStorage.setItem("_token", "Bearer " + res.body);
                  localStorage.removeItem("triggerPopup");
                  localStorage.setItem(
                    "dismissPopup",
                    JSON.stringify({ time: Date.now() })
                  ); // Notify all tabs to dismiss
                  // localStorage.setItem("refreshAllTabs", Date.now()); //Broadcast refresh all tabs
                  window.postMessage({ type: "REFRESH_ALL_TABS" }, "*");

                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 800);
                } else {
                  await performLogout();
                }
              } catch {
                await performLogout();
              }
            },
          });
        }
      } catch {
        await performLogout();
      }
    } else if (
      result.isDismissed &&
      result.dismiss === Swal.DismissReason.timer
    ) {
      console.log("Idle warning dismissed by timer.");
      await performLogout();
      setIsIdle(false);
    } else if (
      result.isDismissed &&
      result.dismiss === Swal.DismissReason.backdrop
    ) {
      console.log("Idle warning dismissed by clicking outside.");
      // localStorage.setItem("refreshAllTabs", Date.now()); //Broadcast refresh all tabs
      // window.location.reload();
      localStorage.setItem(
        "dismissPopup",
        JSON.stringify({ time: Date.now() })
      ); // Notify all tabs to dismiss

      setIsIdle(false);
    } else {
      console.log("Not all tabs are idle. No action taken.");
    }
  }

  const performLogout = async () => {
    const res = await Api("post", "/users/logout");
    if (res) {
      localStorage.clear();
      window.location = "/";
      localStorage.setItem("isTimeSessionOutAD", true);
    }
  };

  const handleOnActive = () => {
    updateIdleState("active");
    setIsIdle(false);
  };
  const { getElapsedTime, getLastActiveTime } = useIdleTimer({
    timeout: isAllowSessionTime,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  const handleStorageChange = (event) => {
    if (event.key === "idleState") {
      const idleStates = JSON.parse(event.newValue || "{}");
      if (idleStates[tabId] !== "idle") {
        const allTabsIdle = Object.values(idleStates).every(
          (state) => state === "idle"
        );
        if (allTabsIdle) {
          console.log("All tabs are idle. Logging out.");
          console.log("logout happens");
          performLogout();
        } else {
          console.log("Not all tabs are idle. Staying logged in.");
        }
      }
    }
    if (event.key === "refreshAllTabs") {
      window.location.reload(); // Refresh the page in all tabs
    }

    if (event.key === "triggerPopup") {
      // Check if the event came from another tab
      const { time } = JSON.parse(event.newValue || "{}");
      if (time) {
        openIdlePopup(time);
      }
    }

    if (event.key === "dismissPopup") {
      // Dismiss popups on all tabs
      Swal.close();
    }
  };

  window.addEventListener("storage", handleStorageChange);
  window.addEventListener("beforeunload", () => {
    const idleStates = JSON.parse(localStorage.getItem("idleState")) || {};
    delete idleStates[tabId];
    localStorage.setItem("idleState", JSON.stringify(idleStates));
  });

  useEffect(() => {
    updateIdleState("active");
  }, []);

  const callFunctionEvery10Min = async () => {
    const currentTimestmp = Date.now();
    const lastActive = getLastActiveTime();
    let diffTimestmp = (currentTimestmp - lastActive) / 1000;
    // Aap yaha background me function call kar sakte ho
    if (diffTimestmp < 600) {
      try {
        // Call the API for refresh token
        let param = {
          userId: localStorage.getItem("_id"),
          oldToken: localStorage.getItem("_token").replace("Bearer ", ""),
        };
        const res = await fetchAPI("POST", `/refreshToken`, param);
        if (res.success) {
          // Set the new token and reload the window
          localStorage.setItem("_token", "Bearer " + res.body); // Assuming `res.body` contains the new token
        } else {
          /* logOut User. */
          // Optional: Logout the app if necessary
          const res = await Api("post", "/users/logout");
          if (res) {
            Swal.close();
            window.localStorage.clear();
            setTimeout(() => {
              history.push("/");
            }, 1000);
          }
        }
      } catch (error) {
        // console.log('VerifyTokenOtp-error', error);
      }
    }
  };

  useEffect(() => {
    socktCall();
    const interval = setInterval(callFunctionEvery10Min, 600000); // 600000 ms = 10 minutes
    return () => clearInterval(interval);
  }, []);

  const socktCall = () => {
    const SockJS = window.SockJS;
    const Stomp = window.Stomp;
    var socket = new SockJS(url + "/funderSocket");
    var stompClient = Stomp.over(socket);
    let chkUsrId = localStorage.getItem("_id");
    // let currentToken = localStorage.getItem("_token");
    stompClient.connect({}, function (frame) {
      // stompClient.setConnected(true);
      stompClient.subscribe("/ADCB/TokenExpired", function (resSocket) {
        let result = JSON.parse(resSocket.body);

        if (result && result.text === "Expired" && result.from === chkUsrId) {
          const resToken = "Bearer " + result.token;
          let currentToken = localStorage.getItem("_token");
          if (currentToken === resToken) {
            // alert('expired Token')
            const res = Api("post", "/users/logout");
            if (res) {
              window.localStorage.clear();
              localStorage.setItem("isTimeSessionOutAD", true);
              window.location.reload();
            }
            stompClient.disconnect();
          }
        }
      });
    });
  };

  return (
    <div>
      <div id="header">
        <div className="row align-items-center">
          <div className="col-6 pl-0">
            <div className="top_left_title">
              <img src={logo} />
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="bell cursor">
              <img
                src={bell}
                width={17}
                height={17}
                onClick={() => {
                  setNotificationsModal(true);
                }}
              />
            </div>

            <Link className="ml-4 headerLogOut" to="#" onClick={() => logout()}>
              <FontAwesomeIcon color="var(--color-f2)" icon={faSignOutAlt} />
            </Link>
          </div>
        </div>
      </div>
      {notificationsModal && (
        <Notifications
          openModal={notificationsModal}
          closeModal={closeModal}
          data={[]}
        />
      )}
    </div>
  );
};

export default Header;
