// export const socketUrl = 'http://127.0.0.1:4001';

//Production

// export const url = "https://java.efunder.ai";
// export const monitorUrl = 'https://app.efunder.ai';
// export const socketUrl = 'https://socket.efunder.ai';

//Staging
// export const url = "https://javastage.efunder.ai";
// export const monitorUrl = "https://appstage.efunder.ai";
// export const socketUrl = "https://socketstage.efunder.ai";


//dev
export const url = "https://javaapidev.efunder.ai";
export const monitorUrl = "https://appdev.efunder.ai";
export const socketUrl = "https://socketdev.efunder.ai";

/* common value */
export const isAllowSessionTime = 59 * 60 * 1000; // 14 min (14 * 60 * 1000)
export const v2SiteKey = "6LdUxM0qAAAAAPsKDlo3VKE7c9swYGEUkR4puzae";
